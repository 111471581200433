import { StepSyncProgress, IStepSyncProgressOptions, IStepSyncProgressJSON } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';

export interface IAppProgressJSON extends IStepSyncProgressJSON {
    appsToDownload: number;
    appsDownloaded: number;
    appsFailedToDownload: number;
    webResourcesToDownload: number;
    webResourcesDownloaded: number;
    webResourcesFailedToDownload: number;
}

export interface IAppProgressOptions extends IStepSyncProgressOptions {
    appsToDownload?: number;
    appsDownloaded?: number;
    appsFailedToDownload: number;
    webResourcesToDownload?: number;
    webResourcesDownloaded?: number;
    webResourcesFailedToDownload?: number;
}

export class AppProgress extends StepSyncProgress {
    public appsToDownload: number = 0;
    public appsDownloaded: number = 0;
    public appsFailedToDownload: number = 0;

    public webResourcesToDownload: number = 0;
    public webResourcesDownloaded: number = 0;
    public webResourcesFailedToDownload: number = 0;

    private get progressStatusTotal(): number {
        return this.webResourcesToDownload;
    }

    public get progressStatusPending(): number {
        return ((this.progressStatusTotal - this.webResourcesDownloaded - this.webResourcesFailedToDownload) / this.progressStatusTotal) * 100;
    }

    public get progressStatusSuccess(): number {
        return (this.webResourcesDownloaded / this.progressStatusTotal) * 100;
    }

    public get progressStatusError(): number {
        return (this.webResourcesFailedToDownload / this.progressStatusTotal) * 100;
    }

    get progressStatusFilesCompleted(): number {
        return this.webResourcesDownloaded;
    }

    get progressStatusFilesToDownload(): number {
        return this.webResourcesToDownload;
    }

    constructor(options: IAppProgressOptions | IAppProgressJSON) {
        super(options);

        options.appsToDownload && (this.appsToDownload = options.appsToDownload);
        options.appsDownloaded && (this.appsDownloaded = options.appsDownloaded);
        options.appsFailedToDownload && (this.appsFailedToDownload = options.appsFailedToDownload);

        options.webResourcesToDownload && (this.webResourcesToDownload = options.webResourcesToDownload);
        options.webResourcesDownloaded && (this.webResourcesDownloaded = options.webResourcesDownloaded);
        options.webResourcesFailedToDownload && (this.webResourcesFailedToDownload = options.webResourcesFailedToDownload);
    }

    public toJSON(): IAppProgressJSON {
        return Object.assign(super.toJSON(), this);
    }
}
